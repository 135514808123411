import clsx from "clsx";
import styles from "./websocket-connection-info.module.css";
import AppContext from "@context/app-context";
import { useContext } from "react";
import {
    Wifi324Regular,
    Wifi224Regular,
    Wifi124Regular,
    WifiOff24Filled,
} from "@fluentui/react-icons";

const PingInfo = ({ ms, profile }) => {
    const { isMobile } = useContext(AppContext);

    if (profile) {
        if (ms === -1) {
            return (
                <span className={clsx(styles.connection)}>
                    <WifiOff24Filled className="fs-3 me-1" />{" "}
                    {!isMobile && "- Mất Kết Nối"}
                </span>
            );
        }
        if (ms >= 0 && ms <= 100) {
            return (
                <span
                    className={clsx(styles.connection)}
                    style={{ color: "#00bc64" }}
                >
                    <Wifi124Regular className="fs-2 me-1" />
                    {ms}ms {!isMobile && "- Tốt"}
                </span>
            );
        }
        if (ms > 100 && ms <= 500) {
            return (
                <span className={clsx("text-warning", styles.connection)}>
                    <Wifi224Regular className="fs-2 me-1" />
                    {ms}ms {!isMobile && "- Trung Bình"}
                </span>
            );
        }
        return (
            <span className={clsx("text-danger", styles.connection)}>
                <Wifi324Regular className="fs-2 me-1" />
                {ms}ms {!isMobile && "- Không Ổn Định"}
            </span>
        );
    }
    return (
        <span className={clsx("text-danger", styles.connection)}>
            Bạn chưa đăng nhập
        </span>
    );
};
export default PingInfo;
