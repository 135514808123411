import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import style from "./websocket-connection-info.module.css";
import clsx from "clsx";
import PingInfo from "./ping-info";
import footerStyles from "../../../src/layouts/footer/footer.module.css";

const WebSocketClient = ({ socketIO, profile }) => {
    const [ping, setPing] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            socketIO?.emit("heartbeat");
        }, 30_000);

        socketIO?.on("heartbeat", (m) => { });

        return () => {
            clearInterval(intervalId);
        };
    }, [socketIO]);

    // Check network connection
    let lastPing = Date.now();
    useEffect(() => {
        socketIO?.emit("ping");

        const pingIntervalId = setInterval(() => {
            lastPing = Date.now();
            socketIO?.emit("ping");

            socketIO?.on("pong", () => setPing(Date.now() - lastPing));
        }, 3000);

        return () => {
            clearInterval(pingIntervalId);
        };
    }, [socketIO]);

    return (
        <div className={clsx(style.status_bar, "align-items-center")}>
            <div className={clsx(style.status_desc)}>
                <span
                    className={clsx("description", footerStyles.desc)}
                >
                    {new Date().getFullYear()} - Tất cả quyền được bảo lưu bởi <a href='https://adscivil.vn' target='_blank'><span className="fw-bold">
                        <span className="text-success">ADS</span><span className="text-light">Civil</span>
                    </span></a>
                </span>
                <PingInfo ms={ping} profile={profile} />
            </div>
        </div>
    );
};

WebSocketClient.propTypes = {
    socketIO: PropTypes.shape({
        auth: PropTypes.object.isRequired,
        connected: PropTypes.bool.isRequired,
    }),
    profile: PropTypes.object,
};

export default WebSocketClient;
