import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import "../assets/css/bootstrap.grid.min.css";
import "../assets/css/feather.css";
import "../assets/scss/style.scss";
import "../assets/css/override.css";

import {
    FluentProvider,
    RendererProvider,
    SSRProvider,
    teamsDarkTheme,
    createDOMRenderer,
    IdPrefixProvider,
} from "@fluentui/react-components";

import AppContext from "@context/app-context";
import WebsocketContext from "@context/websocket-context";

import WebSocketConnectionInfo from "../components/websocket-connection-info";
import Swal from "sweetalert2";
import Responsive from "../constants/responsive";

import NextNProgress from "nextjs-progressbar";
import WebSocketNotification from "../components/websocket-notification";
import { getAuthenticatedUserSession } from "@lib/network/auth";
import { socketInit } from "../lib/network/web-socket-init";

const ADSCivilBimHubApp = ({ Component, pageProps }) => {
    const router = useRouter();

    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [collapsed3D, setCollapsed3D] = useState(true);
    const [rightArrowCollapse, setRightArrowCollapse] = useState(false);
    const [socketIO, setSocketIO] = useState(null);
    const [workerCurrent, setWorkerCurrent] = useState(null);

    const [webSocketEvents, setWebSocketEvents] = useState(null);
    const [newNotification, setNewNotification] = useState({
        id: 0,
        component: null,
        data: null,
    });

    const { isMobile, isPCSmall, isSmLayout, viewHeight } = Responsive();

    useEffect(() => {
        getAuthenticatedUserSession()
            .then((res) => {
                localStorage.setItem(
                    "fullname",
                    res.authenticated_user.fullname
                );
                localStorage.setItem("email", res.authenticated_user.email);

                setWebSocketEvents(res.websocket_message_events);
                setAuthenticatedUser({
                    ...res.authenticated_user,
                    websocket_auth_token: res.websocket_auth_token,
                });
            })
            .catch((ex) => {
                if (
                    ex.message.includes("401") &&
                    router.asPath !== "/auth/login/"
                ) {
                    setAuthenticatedUser(false);
                    Swal.fire({
                        confirmButtonText: "Đăng Nhập Ngay",
                        text: "Bạn có muốn đăng nhập ngay bây giờ?",
                        icon: "question",
                        cancelButtonText: "Khoan Đã",
                        showCancelButton: true,
                    }).then((userChoice) => {
                        if (userChoice?.value) {
                            router.push("/auth/login");
                        }
                    });
                }
            });
    }, []);

    useEffect(() => {
        if (authenticatedUser) {
            socketInit(
                {
                    websocketAuthToken: authenticatedUser.websocket_auth_token,
                    userId: authenticatedUser.userId,
                    username: authenticatedUser.username,
                },
                function handleOnSocketConnected(socket) {
                    setSocketIO(socket);
                }
            );
        }
    }, [authenticatedUser]);

    const authState = useMemo(
        () => ({
            profile: authenticatedUser,
            setProfile: setAuthenticatedUser,
            collapsed,
            setCollapsed,
            rightArrowCollapse,
            setRightArrowCollapse,
            isMobile,
            isPCSmall,
            isSmLayout,
            viewHeight,
            collapsed3D,
            setCollapsed3D,
            workerCurrent,
            setWorkerCurrent,
        }),
        [
            authenticatedUser,
            collapsed,
            rightArrowCollapse,
            isMobile,
            isPCSmall,
            isSmLayout,
            viewHeight,
            collapsed3D,
        ]
    );

    const webSocketState = useMemo(
        () => ({
            socketIO,
            setSocketIO,
            webSocketEvents,
            setWebSocketEvents,
            newNotification,
            setNewNotification,
        }),
        [socketIO, newNotification]
    );

    return (
        <RendererProvider renderer={createDOMRenderer()}>
            <SSRProvider>
                <NextNProgress color="#f27d0c" />
                <AppContext.Provider value={authState}>
                    <WebsocketContext.Provider value={webSocketState}>
                        <IdPrefixProvider value="BIM-HUB">
                            <FluentProvider theme={teamsDarkTheme}>
                                <Component {...pageProps} />

                                <WebSocketConnectionInfo
                                    socketIO={socketIO}
                                    profile={authenticatedUser}
                                />

                                {webSocketEvents &&
                                    socketIO?.connected &&
                                    authenticatedUser && (
                                        <WebSocketNotification
                                            socketIO={socketIO}
                                            webSocketEvents={webSocketEvents}
                                            key={
                                                "websocket-global-notification"
                                            }
                                        />
                                    )}
                            </FluentProvider>
                        </IdPrefixProvider>
                    </WebsocketContext.Provider>
                </AppContext.Provider>
            </SSRProvider>
        </RendererProvider>
    );
};

ADSCivilBimHubApp.propTypes = {
    Component: PropTypes.elementType,
    pageProps: PropTypes.shape({
        className: PropTypes.string,
    }),
};

export default ADSCivilBimHubApp;
