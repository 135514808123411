import { useState, useEffect } from "react";

function Responsive() {
    const [windowWidth, setWindowWidth] = useState(0);
    const mobile = 991;
    const smallMobile = 575;
    const pcSmall = 1199;
    const pcMedium = 1499;
    const laptopMd = 1599;
    const medium = 1399;
    const large = 1699;
    const smLayout = 767;

    const topbarHeight = 50;
    const footerHeight = 30;
    const viewHeight = `calc(100vh - ${topbarHeight}px - ${footerHeight}px)`;

    let lastUpdated = null;

    useEffect(() => {
        const DB_SECOND = 1000;
        const handleResize = () => {
            if (Date.now() - lastUpdated >= DB_SECOND || !lastUpdated) {
                lastUpdated = Date.now();
                setWindowWidth(window.innerWidth);
            }
        };
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // non PC
    const isMobile = windowWidth <= mobile;
    const isSmallMobile = windowWidth <= smallMobile;
    const mobileAndTablet = smallMobile < windowWidth && windowWidth <= mobile;

    // PC
    const isPCSmall = mobile < windowWidth && windowWidth <= pcSmall;
    const isPCMedium = pcSmall < windowWidth && windowWidth <= pcMedium;
    const isLaptopMd = pcSmall < windowWidth && windowWidth <= laptopMd;
    const isMedium = mobile < windowWidth && windowWidth <= medium;
    const isLarge = medium < windowWidth && windowWidth <= large;
    const isExtraLarge = windowWidth > large;

    // mobile
    const isSmLayout = windowWidth <= smLayout;

    // tablet
    const isMdLayout = smLayout < windowWidth && windowWidth <= mobile;
    const smTablet = smallMobile < windowWidth && windowWidth <= smLayout;

    return {
        windowWidth,
        isMobile,
        isSmallMobile,
        isPCSmall,
        isPCMedium,
        isMedium,
        isLarge,
        isExtraLarge,
        isSmLayout,
        isMdLayout,
        mobileAndTablet,
        smTablet,
        isLaptopMd,
        viewHeight,
    };
}

export default Responsive;
