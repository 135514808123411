import { io } from "socket.io-client";

let isConnected = false;

export async function socketInit(
    { websocketAuthToken, userId, username },
    handleOnSocketConnected: Function
) {
    if (websocketAuthToken && !isConnected) {
        try {
            const websocketEndpoint = process.env.NEXT_PUBLIC_SOCKET_HOST;

            const socket = io(websocketEndpoint, {
                withCredentials: true,
                transports: ["pooling", "websocket"],
                secure: true,
                path: "/bim-ws/v1/",
                reconnectionDelay: 1_500,
                reconnectionDelayMax: 10_000,
                timeout: 15_000,
                auth: {
                    user_id: userId,
                    username: username,
                    auth_token: websocketAuthToken,
                },
                rejectUnauthorized: false,
                extraHeaders: {
                    "Sec-WebSocket-Extensions": "dkm",
                    "Sec-WebSocket-Protocol": "bim-ws",
                    "Sec-WebSocket-Sign": "taiamk.pro",
                },
                query: {
                    user_id: userId,
                    v: 3,
                },
            });

            socket.on("connect", function () {
                handleOnSocketConnected(this);
                isConnected = true;
            });
            socket.on("error", (err) => {
                alert(err);
            });
        } catch (e: any) {
            console.log(e);
            isConnected = false;
        }
    }
}
