import PropTypes from "prop-types";
import {
    useId,
    Toaster,
    useToastController,
    Toast,
} from "@fluentui/react-components";
import { useContext, useEffect } from "react";

import WebSocketContext from "@context/websocket-context";

const WebSocketNotification = ({ socketIO, webSocketEvents }) => {
    const { newNotification } = useContext(WebSocketContext);

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    useEffect(() => {
        if (!newNotification.id) {
            return;
        }
        dispatchToast(
            <Toast appearance="inverted">{newNotification.toast}</Toast>,
            {
                position: "top-end",
                timeout: 2000,
                intent: "success",
            }
        );
    }, [newNotification.id]);

    return (
        <>
            <Toaster toasterId={toasterId} limit={3} />
        </>
    );
};

WebSocketNotification.propTypes = {
    socketIO: PropTypes.object.isRequired,
    webSocketEvents: PropTypes.object.isRequired,
};

export default WebSocketNotification;
