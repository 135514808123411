export interface IRequestHeader {
    withSecutityHeader: boolean;
    withJSONType?: boolean;
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD";
    body?: Record<string, any>;
    duplex?: string;
    customHeader?: string;
    lang?: "vi" | "en";
    cache?: any;
}

export class RequestHeader {
    private static throwIfHttpMethodIsInvalid(method: string): void {
        if (
            !["GET", "POST", "PUT", "DELETE", "PATCH", "HEAD"].includes(method)
        ) {
            throw new Error("Method property is required!");
        }
    }

    private static withSecurityHeader(headers: any): any {
        return {
            ...headers,
            mode: "cors",
            credentials: "include",
        };
    }

    private static withJSONType(headers: any): any {
        return {
            ...headers,
            headers: {
                ...headers.headers,
                "content-type": "application/json",
                accept: "application/json",
            },
        };
    }

    private static isJSON(body: any): any {
        try {
            return JSON.parse(JSON.stringify(body));
        } catch (e) {
            return false;
        }
    }

    private static withCustomHeader(headers: any, customHeader: string): any {
        return {
            ...headers,
            headers: {
                ...headers.headers,
                "content-type": customHeader,
            },
        };
    }

    private static withBody(headers: any, body: any): any {
        const isJSONBody = this.isJSON(body);


        if (isJSONBody && Object.keys(body).length) {
            return {
                ...headers,
                body: JSON.stringify(body),
            };
        }
        const isFormDataBody = body instanceof FormData;
        if (isFormDataBody || body) {
            return {
                ...headers,
                body,
            };
        }

        return {
            ...headers,
        };
    }

    private static withDuplex(headers: any, duplex: any): any {
        return {
            ...headers,
            duplex,
        };
    }

    private static withLanguage(headers: any, language: string): any {
        return {
            ...headers,
            headers: {
                ...headers.headers,
                "Accept-Language": language,
            },
        };
    }

    public static build({
        withSecutityHeader = false,
        withJSONType = false,
        method = "GET",
        body,
        duplex = null,
        customHeader = null,
        lang = "vi",
        cache,
    }: IRequestHeader): any {
        this.throwIfHttpMethodIsInvalid(method);

        let baseHeader: any = {};

        if (withSecutityHeader) {
            baseHeader = this.withSecurityHeader(baseHeader);
        }

        if (withJSONType) {
            baseHeader = this.withJSONType(baseHeader);
        }

        if (body) {
            baseHeader = this.withBody(baseHeader, body);
        }

        if (duplex) {
            baseHeader = this.withDuplex(baseHeader, duplex);
        }

        if (customHeader) {
            baseHeader = this.withCustomHeader(baseHeader, customHeader);
        }

        if (!cache) {
            cache = "default";
        }

        baseHeader = this.withLanguage(baseHeader, lang);

        const finalHeader = {
            ...baseHeader,
            method,
            cache,
        };

        console.log(finalHeader);

        return finalHeader;
    }
}
