import { stringify } from "query-string";

export class Auth {
    static Login = "auth/login";
    static Logout = "logout";
    static AuthStatus = "auth-status";
    static SearchMember = "users";
}

export class User {
    static SearchMember = "users";
}

export class Project {
    static IfcsMetaInProject = "projects/:pid/ifcs";
    static MyProject = "projects";
    static UploadProject = "projects";
    static MemberInfoProject = "projects/:pid/members";
    static ProjectOverview = "projects/:pid/ifc-overview";
    static SearchProject = "projects/search";
    static DeleteProject = "projects/:pid";
    static ListAll3DModelsInProject = "projects/:pid/models";
    static ListAll3DModelsInProjectPublic = "projects/shared/:share_id/models";
    static ProjectDetail = "projects/:pid";
    static ShareProject = "projects/:pid/share";
    static LoadShareLink = "projects/:pid/share";
    static SharedProject = "projects/shared/:shared_key";
}

export class IfcModel {
    static GenerateSignedUrlForUpload = "ifc-model/signed-url/upload";
    static GenerateSignedUploadUrl = "ifc-model/signed-url/file";
    static GenerateSignedUrlProperties = "ifc-model/signed-url/properties";
    static GenerateSignedUrlStructure =
        "ifc-model/signed-url/spatial-structures";
    static UploadIfcModel = "ifc-model";
    static GetIfcModelLink = "ifc-model/signed-url/view";
    static LoadTopic = "ifc-comment/topics";
    static BulkUploadIfcModels = "ifc-model/bulk-upload";
    static DeleteIfcModel = "ifc-model/:model_id";
    static ShareModel = "ifc-model/share";
    static UpdateShareLink = "ifc-model/share/update";
    static ViewShare = "ifc-model/share/view";
    static Revions = "ifc-model/:model_id/revions/";
    static SetRevion = "ifc-model/:model_id/revions/:rev_id";
    static IfcMetaData = "ifc-model/:model_id/meta-data";
    static UpdateIfcMeta = "ifc-model/:model_id/update-meta";
    static GetIfcSpatialStructure = "ifc-model/:model_id/spatial-structure";
    static SaveGltfFile = "ifc-model/export-gltf";
    static LoadModelGltf = "ifc-model/:model_id/load-gltf";
    static GetSingleIfcProperty = "ifc-model/:model_id/properties/:property_id";
    static GetIfcModelsInProject = "ifc-model";
    static CreateTaskIfcModel = "ifc-model/create-task";
    static UpdateAmountGltf = "ifc-model/:model_id/amount-gltf";
    static UpdateStatusGltf = "ifc-model/:model_id/status-part-gltf";
    static CommitUploadSpatialFile = "ifc-model/spatial-sturctures/commit";
    static CommitUploadFile = "ifc-model/upload-file/commit";
    static GetDetailIfcModel = "ifc-model/:model_id/detail";
}

export class Storage {
    static GenerateUploadSignedUrl = "storage/generate-upload-signed-url";
    static MakePublic = "storage/make-public";
}

export class IfcComment {
    static GetComments = "ifc-comment";
}

export class Task {
    static CreateTask = "tasks";
    static List = "tasks/:model_id";
    static TaskCommentList = "tasks-comment/:task_id";
}

export class TaskCategory {
    static CreateCategory = "tasks-category";
    static List = "tasks-category/:ifc_id";
}

export class CMS {
    // article
    static GetArticles = "cms/articles";
    static GetSingleArticle = "cms/articles/:articleId";
    static ReadArticle = "cms/articles/read/:articleId";
    static CreateArticle = "cms/articles";
    static UpdateArticle = "cms/articles/:id";
    static DeleteArticle = "cms/articles/:id";

    // category
    static GetAllCategory = "cms/categories";
    static CreateCategory = "cms/categories";
    static UpdateCategory = "cms/categories/:id";
    static getCategoriesDropdown = "cms/categories/dropdown";
    static Delete = "cms/categories/:id";
    static UploadImage = "cms/articles/image";

    // product
    static GetAllItems = "cms/items";
    static ReadOnlyAllItems = "cms/items/readonly";
    static GetSingleItem = "cms/items/:id";
    static ReadOnlySingleItem = "cms/items/readonly/:id";
    static UpdateItem = "cms/items/:id";
    static CreateItem = "cms/items";
    static DeleteItem = "cms/items/:id";
    static UploadItemImage = "cms/items/image";
}

export const getPath = (path: string, queryStringObject = {}): string =>
    `${process.env.NEXT_PUBLIC_API_DOMAIN}/${path}?${stringify({
        ...queryStringObject,
    })}`;
