import { Auth, getPath } from "@utils/api-path";
import { RequestHeader } from "@utils/request-header-builder";

async function syncWebsocketMessageEvents() {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_DOMAIN}/ws-events.json`,
        RequestHeader.build({
            method: "GET",
            withSecutityHeader: true,
            withJSONType: true,
        })
    );
    const ws_events = await res.json();
    return ws_events;
}

async function getAuthenticatedUserSession() {
    const apiResponse: any = await fetch(
        getPath(Auth.AuthStatus),
        RequestHeader.build({
            method: "GET",
            withSecutityHeader: true,
            withJSONType: true,
        })
    );

    if (!apiResponse.ok) {
        throw new Error(apiResponse.status);
    }

    const [{ data, meta }, wsEvents] = await Promise.all([
        apiResponse.json(),
        syncWebsocketMessageEvents(),
    ]);

    return {
        websocket_auth_token: meta,
        websocket_message_events: wsEvents,
        authenticated_user: data,
    };
}

export { getAuthenticatedUserSession };
